(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

var _widget = require('./widget');

var _widget2 = _interopRequireDefault(_widget);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// eslint-disable-next-line func-names
(function () {
    // eslint-disable-next-line global-require
    window.EnvyCrmWidget = new _widget2.default();
})();

},{"./widget":2}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var EnvyCrmWidget = function () {
    function EnvyCrmWidget() {
        _classCallCheck(this, EnvyCrmWidget);

        this.blocks = null;
        this.frameID = null;
        this.parent = window.top;
    }

    /**
     * Первичная инициализация
     * @param blocks
     */


    _createClass(EnvyCrmWidget, [{
        key: 'init',
        value: function init(blocks) {
            this.blocks = blocks;

            this.subscribe();
            this.send('loaded');
        }

        /**
         * Отправка эвента об инициализации
         */

    }, {
        key: 'initialize',
        value: function initialize() {
            this.send('init', {
                widgetID: this.frameID,
                blocks: Object.keys(this.blocks)
            });
        }

        /**
         * Подписка на эвенты от окна
         */

    }, {
        key: 'subscribe',
        value: function subscribe() {
            var _this = this;

            window.addEventListener('message', function (event) {

                if (event.data.method === 'loaded') {
                    _this.frameID = event.data.data.widgetID;
                    _this.initialize();
                }
                if (event.data.method === 'getBlocks') {
                    var type = event.data.data.type;
                    var params = event.data.data.params;

                    if (_this.blocks[type]) {
                        _this.send('blocks', {
                            type: type,
                            widgetID: _this.frameID,
                            blocks: _this.blocks[type](params)
                        });
                    }
                }
            });
        }

        /**
         * отправка эвентов
         * @param method
         * @param data
         */

    }, {
        key: 'send',
        value: function send(method, data) {
            var message = {
                name: 'envy::widget',
                method: method
            };
            if (data) {
                message['data'] = data;
            }

            window.top.postMessage(message, '*');
        }
    }]);

    return EnvyCrmWidget;
}();

exports.default = EnvyCrmWidget;

},{}]},{},[1]);
